@import "./mixin.scss";
.footer {
  background-color: #191f26;
  padding: 2rem 0;
  @include for-mobile {
    padding: 2rem 1rem;
  }
}
.footerSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  justify-items: end;
  @include for-mobile {
    grid-template-columns: 1fr;
    // grid-template-rows: 1fr 1fr 1fr;
    justify-items: start;
    row-gap: 2rem;
  }
}
.footerAboutSection,
.footerHelpSection {
  display: grid;

  row-gap: 1rem;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
  }
}
.footerNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  border-top: 1px solid #ccc;
  margin-top: 2rem;
  color: #727679;
  ul {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    list-style-type: none;
    li {
      a {
        color: #727679;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
    @include for-mobile {
      flex-direction: column;
      row-gap: 1rem;
      margin-bottom: 1rem;
    }
  }
  @include for-mobile {
    flex-direction: column-reverse;
  }
}
