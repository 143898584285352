@import "./mixin.scss";
// book now page styling
.searchContainer {
  margin-top: 4rem;
  h1 {
    font-size: 1rem;
    text-align: center;
    @include for-mobile {
      font-size: 12px;
    }
    // color: #ffcc00;
  }
  
  h2 {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 3rem;
    @include for-mobile {
      font-size: 12px;
    }
    // color: #ffcc00;
  }
  h3 {
    font-size: 2rem;
    @include for-mobile {
      font-size: 20px;
    }
    // color: #ffcc00;
  }

}
.searchBar {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  column-gap: 2rem;
  margin: 4rem 0;
  align-items: self-start;
  @include for-mobile {
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 0 0.5rem;
  }
}
// airport picking styling
.airportPicking {
  width: fit-content;
  color: blue;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
  &:hover {
    text-decoration: underline;
  }
}
.airportModal {
  display: flex;
  flex-direction: column;
}
.vehicleListing {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  padding-bottom: 2rem;
}
.listItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  justify-items: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  transition: all ease-in-out 0.3s;
  padding: 1rem;
  //   cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  h2 {
    font-size: 4rem;
    @include for-mobile {
      font-size: 20px;
    }
    // color: #ffcc00;
  }
  p {
    font-size: 2rem;
    text-transform: capitalize;
    @include for-mobile {
      font-size: 18px;
    }
  }
  @include for-mobile {
    row-gap: 0.5rem;
  }
}
.desc {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  p {
    font-size: 1.1rem;
    text-transform: capitalize;
    @include for-mobile {
      font-size: 12px;
    }
  }
  img {
    max-width: 500px;
    height: auto;
    @include for-mobile {
      width: 150px;
    }
  }
  @include for-mobile {
    align-items: center;
  }
}
// button
.button52 {
  font-size: 16px;
  //   font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: 500;
}

.button52:after {
  content: "";
  background-color: #ffe54c;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button52 {
    padding: 13px 50px 13px;
  }
}
// booking form
.bookingFormContainer {
  display: grid;
  grid-template-columns: 1fr 30%;
  grid-template-rows: auto auto;
  column-gap: 2rem;
  row-gap: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  h2 {
    grid-area: 1/1/2/3;
    @include for-mobile {
      grid-area: 1/1/2/2;
    }
  }
  @include for-mobile {
    grid-template-columns: 1fr;
    // grid-template-rows: ;
  }
}
.bookingForm {
  display: grid;
  row-gap: 2rem;
}
.passengerDetail {
  // padding: 1rem;

  p {
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .detailFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    @include for-mobile {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }
  .extraFields {
    .formFields {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1rem;
      @include for-mobile {
        grid-template-columns: 1fr;
      }
    }
  }
}
.bookingDetail {
}
.pickingOption {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  // padding: 1rem;
  p {
    font-weight: 500;
  }
  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    .timings {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      @include for-mobile {
        grid-template-columns: 1fr;
        row-gap: 1rem;
      }
    }
    @include for-mobile {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }
}
// summary
.bookingSummary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  align-self: self-start;
  h3 {
    background-color: black;
    color: #fff;
    padding: 1rem;
  }

  .summaryView {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 1rem;
  }
  .summaryContent {
    display: grid;
    grid-template-columns: 20% 80%;
    column-gap: 1rem;
  }
  button {
    margin: 1rem auto;
    width: 90%;
  }
}
// text area
.textarea {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 3px;
  font-family: "Roboto";
}
// empty modal styling
.emptyModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 30%;
  background-color: #fff;
  // box-shadow: 24;
  // padding: 0 2rem;
  border-radius: 8px;
  @include for-mobile {
    min-width: 80%;
  }
}
.modalHeader {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  border-bottom: 1px solid #ccc;
  align-items: center;
  button {
    // width: 50%;
  }
  p {
    text-transform: capitalize;
    width: 70%;
    text-align: center;
    font-weight: 500;
  }
}
.modalBody {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}
// success screen styling
.successScreen {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
}
.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  row-gap: 1rem;
  img {
    width: 250px;
  }
  p {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  @include for-mobile {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
}
//
.errorClass {
  border: 2px solid red !important;
  border-radius: 3px !important;
}
/// custom search for location
.customSearchContainer {
  display: flex;
  flex-direction: column;
}
.customSearchList {
  overflow: auto;
}
