@import "./mixin.scss";
// hero section
.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    /* Gradient overlay */ url("../assets/hero-background.jpg"); /* Image URL */
  padding: 10rem 0 0 0;
  background-position: center;
  background-size: cover;
  position: relative; /* Add this to position the overlay content correctly */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.heroContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  h1 {
    color: #fff;
    font-size: 3rem;
    text-align: center;
    padding-bottom: 3rem;
    @include for-mobile {
      font-size: 2rem;
      text-align: center;
    }
  }
  h2 {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
    @include for-mobile {
      font-size: 1rem;
      text-align: center;
    }
  }
  p {
    color: #fff;
    font-size: 2rem;
    @include for-mobile {
      font-size: 1rem;
      text-align: center;
    }
  }
  @include for-mobile {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.callToAction {
  background-color: #ffcc00;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  padding: 2.5rem;
  span {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }
}
// over view points
.overviewSec {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem 0;
}
.vehicleList {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;

  li {
    img {
      width: 250px;
      @include for-mobile {
        width: 100%;
        height: auto;
      }
    }
  }
  @include for-mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 0 0.5rem;
  }
}
.pointList {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 2rem;

  li {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.5rem;
    h3 {
      @include for-mobile {
        font-size: 0.8rem;
      }
      // color: #ffcc00;
    }
    p {
      @include for-mobile {
        font-size: 0.8rem;
      }
      // color: #ffcc00;
    }
  }
  @include for-mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 0 0.5rem;
  }
}
.overviewIcon {
  align-self: start;
}
// partners section
.partnersContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem 0;
  ul {
    width: 100%;
    display: grid !important;
    list-style: none;
    grid-template-columns: repeat(4, 1fr);
    align-content: center;
    justify-items: center;
    
    li {
      align-self: center;
      justify-content: space-around;
      
    }
    img{
      width: 200px;
    }

    @include for-mobile {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      column-gap: 1rem;
      row-gap: 2rem;
      padding: 0 0.5rem;
    }
  }

 
}

