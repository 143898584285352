@import "./mixin.scss";
.bannerSection {
  padding: 5rem;
  background-position: 30% 25%;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
}
.content {
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 3rem 0;
  column-gap: 2rem;
  position: relative;
  @include for-mobile {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    padding: 2rem 1rem;
  }
}
.storySection {
  list-style: none;
  display: grid;
  row-gap: 3rem;
  li {
    h3 {
      margin: 0.5rem 0;
    }
  }
}
.whySection {
  border: 1px solid #ccc;
  border-radius: 5px;
  position: sticky;
  top: 0;
  right: 0;
  height: fit-content;
  h4 {
    background-color: #111;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
}
.whylist {
  list-style-type: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;
  li {
    h3 {
      margin: 0.5rem 0;
    }
  }
}
.bookNow {
  background-color: #ffcc00;
  color: #000;
  padding: 1rem;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  &:hover {
    box-shadow: 3px 3px rgba($color: #000000, $alpha: 0.1);
  }
}
//
.termsContent {
  ul {
    list-style: none;
    li {
      h3 {
        margin: 1.5rem 0;
      }
    }
  }
  table {
    width: 100%;
    th,
    td {
      padding: 1rem 0.5rem;
      border: 1px solid #eee;
    }
    td {
      text-align: center;
    }
  }
}
