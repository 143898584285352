@import "./mixin.scss";
.header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.navbar {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.navList {
  list-style-type: none;
  display: flex;
  column-gap: 2rem;
  align-items: center;
  li a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    text-transform: capitalize;
    &:hover {
      transition: all ease 0.2s;
      text-decoration: underline;
      //   color: yellow;
    }
  }
  @include for-mobile {
    display: none;
  }
}
.logoIcon {
  width: 100%;
  max-width: 290px;
  height: auto;
}
.toggleBtn {
  display: none !important;
  @include for-mobile {
    display: block !important;
  }
}
//
.mobileNavList {
  display: flex;
  flex-direction: column;
  li {
    padding: 1rem;
    a {
      color: #000;
      text-decoration: none;
    }
  }
}
