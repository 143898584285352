a {
  text-decoration: none;
}
.blog-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  h1 {
    font-size: 2rem;
    text-align: center;   
    padding-bottom: 3rem;
  }
  h2 {
    font-size: 1rem;
    text-align: center;   
    padding-bottom: 3rem;
  }
  
}

.featured-blogs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
  height: 100vh;
  margin-bottom: 50px;

  .featured-blog {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .blog-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background: rgba(0, 0, 0, 0.6);
      color: white;
    }
  }

  .featured-blog-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

.blog-list {
  display: flex;

  .blog-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 30px;
  }

  .ad-section {
    width: 20%;
    padding-left: 20px;
  }
}

.blog-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .blog-content {
    padding: 15px;
  }
}
.blog-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;

  .blog-header {
    position: relative;
    margin-bottom: 2rem;

    .blog-header-content {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 20px;
      color: white;
    }

    h1 {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }

    .blog-date {
      font-style: italic;
      margin-bottom: 0;
    }

    .blog-header-image {
      width: 100%;
      height: 400px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .blog-body {
    .blog-description {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1.5rem;
    }
  }
}

.blog-not-found {
  text-align: center;
  font-size: 1.5rem;
  color: #666;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .blog-content {
    padding: 10px;

    .blog-header {
      h1 {
        font-size: 2rem;
      }

      .blog-header-image {
        height: 300px;
      }
    }
  }
}

@media (max-width: 480px) {
  .blog-content {
    .blog-header {
      h1 {
        font-size: 1.5rem;
      }

      .blog-header-image {
        height: 200px;
      }
    }

    .blog-body {
      .blog-description {
        font-size: 1rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .featured-blogs {
    grid-template-columns: 1fr;
  }

  .blog-list {
    flex-direction: column;

    .blog-container,
    .ad-section {
      width: 100%;
    }

    .ad-section {
      margin-top: 20px;
      padding-left: 0;
    }
  }
  .blog-container {
    grid-template-columns: 1fr !important;
  }
  .blog-content h2 {
    font-size: 1.1rem !important;
    text-align: left;
  }
}
