@import "./mixin.scss";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  padding-top: 100px;
  //   background-color: gray;
  font-family: roboto;
  overflow: auto !important;
  padding-right: 0 !important;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.actionBtn {
  background-color: #000;
  color: #fff;
  font-size: 2rem;
  border: none;
  outline: none;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    background-color: #fff;
    color: #000;
    transition: all ease-in 0.3s;
  }
}
h1 {
  font-size: 2rem;
  text-transform: capitalize;
  @include for-mobile {
    text-align: center;
    font-size: 1.5rem;
  }
}
h2 {
  font-size: 2rem;
  text-transform: capitalize;
  @include for-mobile {
    text-align: center;
    font-size: 1.5rem;
  }
}
.PhoneInputInput {
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  &:focus-visible {
    border: 2px solid #ffcc00 !important;
    outline: none;
  }
}
.css-1kbgdyl-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #000 !important;
  background-color: #ffcc00 !important;
}
.css-139q233.Mui-selected {
  color: #000 !important;
  background-color: #ffcc00 !important;
}
